import React, { Component } from 'react'
import { useStaticQuery, graphql } from "gatsby";
import Helmet from 'react-helmet'



const query = graphql`
  query {
    strapiGlobal {
      siteName
      favicon {
        publicURL
      }
      defaultSeo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`;

export default class Meta extends Component {
  render() {
      const { strapiGlobal } = useStaticQuery(query);
      const { defaultSeo, siteName, favicon } = strapiGlobal;

      const fullSeo = { ...defaultSeo };

    return (
      <Helmet>
        <title>{fullSeo.metaTitle}</title>
        <meta property="og:title" content={fullSeo.metaTitle} />
        <meta name="description" content={fullSeo.metaDescription} />
        <meta property="og:description" content={fullSeo.metaDescription} />
        <meta name="robots" content="noindex" />


        <meta property="og:url" content="{url}" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content={fullSeo.metaTitle} />
        <meta name="twitter:description" content={fullSeo.metaDescription} />
        <meta name="twitter:title" content={fullSeo.metaTitle} />
        <meta name="twitter:image" content={fullSeo.shareImage} />
        <meta property="og:image:secure_url" content={fullSeo.shareImage} />
        <meta property="og:image" content={fullSeo.shareImage} />
        <meta property="og:image:alt" content={fullSeo.metaTitle} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="article:published_time" content="{date}" />
        <meta name="twitter:card" content="summary_large_image" />

      </Helmet>
    )
  }
}
