import React from 'react'
import Logo from './Logo'


import './Footer.css'



export default () => (
    <div>
        <div class="full-width-footer white-text">
            <div class="footer footer-container">
                <div class="footer-column-left dark">
                      <h3>Løwe Konditori</h3>
                      <p>Med stor bakeglede lager jeg kaker til både mindre
                      og større anledninger. Jeg har ingen formell utdannelse
                      innen baking, men jeg har en stor interesse og glede for
                      baking. </p>
                </div>
                <div class="footer-column-right">
                <div class="footer-halfcolumn dark list-unstyled">
                      <h3>Nettsiden</h3>
                      <li><a href="/blogg">Blogg</a></li>
                      <li><a href="/prisliste">Prisliste</a></li>
                      <li><a href="#om">Om meg</a></li>
                      <li><a href="#kontakt">Kontakt</a></li>
                </div>
                <div class="footer-halfcolumn dark list-unstyled">
                      <h3>Sosiale medier</h3>
                      <li><a href="https://www.facebook.com/lowekonditori">Facebook</a></li>
                      <li><a href="https://www.instagram.com/lowekonditori">Instagram</a></li>
                </div>
                </div>
            </div>
        </div>
        <div class="footer-center footer">
          <span>
          <div class="container">
            <hr></hr>
            <div class="footer-column-left-bottom hide">
            <div className="Logo" style={{backgroundImage: `url(/images/logo-light.svg)`}}/>
            </div>
            <div class="footer-column">
            {new Date().getFullYear()} © Løwe Konditori
            </div>
          </div>
          </span>
        </div>
    </div>
)
